<template>
  <section class="flex items-center justify-center">
    <div
      class="
        aspect-[16/9]
        bg-white
        container
        flex flex-col
        m-20
        rounded-2xl
        shadow-2xl shadow-WALIGHTBLUE-200
        w-[800px]
        md:flex-row
      "
    >
      <div class="pl-8 px-8 py-4 w-full">
        <img src="/login-pic-forgot-password.svg" class="h-full" />
      </div>
      <div class="flex flex-col items-center justify-center p-8 w-full">
        <div class="mb-14">
          <img src="/wa-logo.png" class="w-48" />
        </div>
        <div class="mb-4">
          <h4
            class="font-bold text-WADARKBLUE-500 text-center text-xs uppercase"
          >
            {{ $t("forgot-password-title") }}
          </h4>
        </div>
        <form class="flex flex-col items-center" @submit.prevent="onSubmit">
          <div
            v-if="message"
            class="
              py-2
              px-6
              bg-green-100
              text-green-700
              my-2
              rounded-xl
              text-sm
              w-full
            "
          >
            <span>{{ message }}</span>
          </div>
          <div
            v-if="error"
            class="py-2 px-6 bg-red-100 rounded-lg my-2 text-sm w-full"
          >
            <span class="text-red-400">
              {{ error }}
            </span>
          </div>
          <div class="mb-4 relative">
            <input
              :placeholder="$t('email')"
              class="input-field outline-none p-2 pl-10 text-sm w-64"
              v-model="email"
            />
            <div
              class="
                absolute
                flex
                inset-y-0
                items-center
                left-0
                pointer-events-none
                px-2
              "
            >
              <img src="/icon-login-email.svg" class="w-3" />
            </div>
          </div>
          <div class="mt-2">
            <button
              class="
                bg-WADARKBLUE-500
                hover:bg-WADARKBLUE-400
                p-3
                rounded-full
                text-sm text-white
                w-64
                uppercase
              "
            >
              {{ $t("send") }}
            </button>
          </div>
        </form>
        <div class="mt-4">
          <router-link
            to="/login"
            class="hover:text-WADARKBLUE-500 mt-3 text-WAORANGE-500 text-sm"
            >{{ $t("remember-password") }}</router-link
          >
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import httpClient from "../services/httpClient";
export default {
  data() {
    return {
      email: "",
      error: null,
      message: null,
      loading: false,
    };
  },
  methods: {
    async onSubmit() {
      this.error = null;
      this.message = false;

      try {
        this.loading = true;
        const { data } = await httpClient.post(`/auth/forgotpassword`, {
          email: this.email,
        });
        this.loading = false;

        if (data.success) {
          this.message = data.message;
          this.email = "";
        }
      } catch (err) {
        this.loading = false;
        this.error =
          err.response && err.response.data.error
            ? err.response.data.error
            : err.error || err.message;
      }
    },
  },
};
</script>

<style scoped>
section {
  min-height: 85vh;
}
</style>
